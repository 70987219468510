var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "PayrollRules" },
    [
      _c(
        "a-form-model-item",
        { attrs: { label: "计薪规则" } },
        [
          _vm.typeNo === "A01"
            ? _c("span", [_vm._v("薪资档案固定值")])
            : _c("a-radio-group", {
                attrs: { options: _vm.plainOptions },
                on: { change: _vm.calcRuleTypeChange },
                model: {
                  value: _vm.form.calcRuleType,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "calcRuleType", $$v)
                  },
                  expression: "form.calcRuleType",
                },
              }),
        ],
        1
      ),
      _vm.form.calcRuleType === "2"
        ? [
            _c(
              "a-form-model-item",
              { attrs: { label: "计算公式类型" } },
              [
                _c("a-radio-group", {
                  attrs: { options: _vm.formulaOptions },
                  on: { change: _vm.formulaTypeChange },
                  model: {
                    value: _vm.form.formula.formulaType,
                    callback: function ($$v) {
                      _vm.$set(_vm.form.formula, "formulaType", $$v)
                    },
                    expression: "form.formula.formulaType",
                  },
                }),
              ],
              1
            ),
            _c("a-form-model-item", { attrs: { label: "计算公式" } }, [
              _c(
                "div",
                {
                  staticClass: "custom-formula",
                  on: { click: _vm.setUpCustomFormula },
                },
                [
                  _vm.badFormula
                    ? _c("span", [_vm._v("点击输入自定义公式")])
                    : _c("span", [
                        _vm._v(
                          _vm._s(_vm._f("formatFormula")(_vm.form.formula))
                        ),
                      ]),
                ]
              ),
              _c(
                "div",
                { staticClass: "formula-budget" },
                [
                  _c(
                    "a-button",
                    {
                      attrs: {
                        disabled: _vm.badFormula,
                        prefix: "bendijisuan",
                      },
                      on: { click: _vm.handleBudget },
                    },
                    [_vm._v("公式测算")]
                  ),
                ],
                1
              ),
            ]),
          ]
        : _vm._e(),
      _vm.typeNo !== "A01" &&
      _vm.form.calcRuleType === "1" &&
      _vm.form.detailList
        ? _c("FormulaList", { attrs: { form: _vm.form } })
        : _vm._e(),
      _c("formulaBudget", { ref: "formulaBudget" }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }